import React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/layout/layout";
import TypeToBlock from "../../components/type-to-block";
import "./terms-of-use.scss";

export const query = graphql`
  query ($languageISO: String!, $language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    header: contentfulModules(
      type: { eq: "Header" }
      node_locale: { eq: $languageISO }
    ) {
      id
      items {
        ... on ContentfulItems {
          id
          link
          linkText
        }
      }
    }
    ribbon: contentfulModules(
      type: { eq: "Ribbon" }
      node_locale: { eq: $languageISO }
    ) {
      id
      heading
    }
    footer: allContentfulModules(
      filter: { type: { eq: "Footer" }, node_locale: { eq: $languageISO } }
    ) {
      nodes {
        id
        heading
        items {
          ... on ContentfulItems {
            id
            link
            linkText
          }
        }
      }
    }
    termsOfService: contentfulPages(
      slug: { eq: "terms-of-service" }
      node_locale: { eq: $languageISO }
    ) {
      slug
      id
      title
      metaDescription
      metaTitle
      modules {
        id
        heading
        name
        subheading
        type
        body {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`;

const TermsOfService = ({
  data: {
    termsOfService: { title, metaTitle, metaDescription, modules },
    ribbon,
    header,
    footer,
  },
}) => {
  return (
    <Layout
      header={header}
      ribbon={ribbon}
      footer={footer}
      hasBanner="white-banner"
    >
      <section className="terms-of-use">
        <div className="container">
          <h1>{title}</h1>
          <div>
            {modules.map((module, key) => (
              <TypeToBlock key={`module-${key}`} module={module} />
            ))}
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default TermsOfService;
